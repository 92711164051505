




















import { Tab } from '@/types/tab';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PageLayout from './PageLayout.vue';

@Component({
  components: { PageLayout },
})
export default class PageTabLayout extends Vue {
  @Prop({ type: Array, required: true }) tabs: Tab[];
  @Prop({ type: String, required: true }) currentTab: string;

  makeTabClass(tab: string): Record<string, boolean> {
    return {
      'tab--active': this.isActive(tab),
      tab: true,
    };
  }

  isActive(tab: string): boolean {
    return tab === this.currentTab;
  }

  selectTab(tab: Tab) {
    this.$emit('change-tab', tab.id);
  }
}
